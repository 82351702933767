<script>
	import { goto } from '$app/navigation';
	import { page } from '$app/stores';
	import Country from '$lib/Components/Forms/Country/Country.svelte';
	import SuperInput from '$lib/Components/Forms/SuperInput/SuperInput.svelte';
	import Button from '$lib/Components/Layout/Button/Button.svelte';
	import {
		handleFormSubmit,
		sendPixelConversions,
	} from '$lib/PageSections/Forms/formUtils';
	import leadSchema from '$lib/PageSections/Forms/schemas/lead.schema.js';
	import { getContext } from 'svelte';
	import toast from 'svelte-french-toast';
	import { superForm } from 'sveltekit-superforms';
	import { valibotClient } from 'sveltekit-superforms/adapters';

	const { user } = getContext('sessionData');

	let loading = false;
	export let data = null;
	export let firstField;
	export let disabled = null;
	export let text = true;

	export let leadFormData;
	const initialFormData =
		data?.forms?.leadForm ?? valibotClient($leadFormData ?? leadSchema);

	export const form = superForm(initialFormData, {
		validators: valibotClient(leadSchema),
		onSubmit({ formData }) {
			loading = true;
			handleFormSubmit(formData, $page.url);
		},
		onResult() {
			loading = false;
		},
		onUpdated({ form }) {
			if (!form.valid) {
				if (form.message?.text) {
					toast.error(form.message.text);
					$message = form.message.text;
				}
			}
			if (form.valid) {
				if (form.message?.text) {
					toast.success(form.message.text);
					$message = form.message.text;
				}
				sendPixelConversions(form.message?.normalizedData);
				$user = {
					firstName: form.data.firstName,
					lastName: form.data.lastName,
					email: form.data.email,
					phone: form.data.phone,
					postalCode: form.data.postalCode,
					country: form.data.country,
				};

				if (form.message?.successUrl) {
					goto(form.message.successUrl);
				}
			}
		},
		onError({ result }) {
			toast.error(result?.error?.message);
			$message = result?.error?.message;
		},
	});
	const { form: innerForm, message, enhance } = form;
	leadFormData = innerForm;

	export let buttonText = 'Request Estimate';
	export let action = '/forms/lead';
</script>

<form
	name="lead"
	method="POST"
	use:enhance
	{action}
	class={['row-auto grid w-full grid-cols-2 gap-x-4', $$props.class ?? ''].join(
		' ',
	)}
>
	{#if text}
		<p class="col-span-full mb-4 p-0 text-left text-sm !text-indow-grey-dark">
			<slot name="text">
				<span>
					Complete this form to receive a customized estimate to insulate your
					space with Indow.
				</span>
			</slot>
		</p>
	{/if}
	<SuperInput
		{form}
		{disabled}
		bind:element={firstField}
		name="firstName"
		label="First Name"
		autocomplete="given-name"
		class="xs:col-span-1"
	/>
	<SuperInput
		{form}
		{disabled}
		name="lastName"
		label="Last Name"
		autocomplete="family-name"
		class="xs:col-span-1"
		required
	/>

	<SuperInput
		{form}
		{disabled}
		type="email"
		name="email"
		label="Email"
		autocomplete="email"
		required
	/>
	<SuperInput
		{form}
		{disabled}
		name="phone"
		type="tel"
		label="Phone"
		autocomplete="tel"
	/>
	<SuperInput
		{form}
		{disabled}
		name="postalCode"
		type="text"
		label="Zip / Postal Code"
		autocomplete="postal-code"
		class="xs:col-span-1"
	/>
	<Country
		{form}
		{disabled}
		name="country"
		label="Country"
		class="xs:col-span-1"
	/>
	<div class="col-span-full mb-4 grid gap-2">
		<p class="col-span-full mb-0 w-full p-0 text-left">
			What issues are you experiencing?
		</p>

		<div
			class="col-span-full mb-2 grid grid-flow-dense grid-cols-[repeat(auto-fit,minmax(max(30%,9rem),1fr))] gap-4"
		>
			<SuperInput
				{form}
				{disabled}
				name="focusTemperature"
				type="checkbox"
				label="Temperature loss"
				class="col-span-1"
			/>
			<SuperInput
				{form}
				{disabled}
				name="focusSound"
				type="checkbox"
				label="Noise from outside"
				class="col-span-1"
			/>
			<SuperInput
				{form}
				{disabled}
				name="focusUV"
				type="checkbox"
				label="UV Light"
				class="col-span-1"
			/>
			<SuperInput
				{form}
				{disabled}
				name="focusEnergy"
				type="checkbox"
				label="High energy use"
				class="col-span-1"
			/>
			<SuperInput
				{form}
				{disabled}
				name="focusOther"
				type="checkbox"
				label="Other"
				class="col-span-1"
			/>
		</div>
	</div>

	<SuperInput
		{form}
		{disabled}
		name="notes"
		type="textarea"
		label="(Optional) For a faster response, include approximate window dimensions for each window:"
	/>
	{#if $message}
		<div
			class:text-green-900={$page.status == 200}
			class:text-red-900={$page.status >= 400}
			class={'col-span-full mb-4'}
		>
			{$message.text ?? $message}
		</div>
	{/if}

	<div class="col-span-2 grid gap-2">
		<Button class="w-full" buttonType="button" {loading} {disabled}
			>{buttonText}</Button
		>
	</div>
</form>
